
































import { debounceProcess } from "@/helpers/debounce";
import { ResponseBranchWarehouse, ResponseListWarehouse, WarehouseResponse } from "@interface/warehouse.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import Vue from "vue";
import { IOption } from "@/models/interface-v2/common.interface";
import { DEFAULT_PAGE_SIZE } from "@/models/constant/global.constant";
import { warehouseService } from "@/services-v2/warehouse.service";

export default Vue.extend({
  name: "CSelectWarehouse",
  props: {
    value: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    forList: {
      type: Boolean,
      default: true,
    },
    byBranch: {
      type: String,
      default: undefined,
    },
  },
  data() {
    this.searchWarehouse = debounceProcess(this.searchWarehouse, 300);
    return {
      loading: false,
      dtListWarehouse: {} as ResponseListWarehouse,
      optWarehouse: [] as IOption<WarehouseResponse>[],
      queryParams: {
        page: 0,
        limit: DEFAULT_PAGE_SIZE,
        search: ""
      }
    };
  },
  watch: {
    value(newValue: string) {
      if (newValue) {
        this.handleMissingOpt();
      }
    },
    byBranch: {
      immediate: true,
      handler: "onChangeBranch"
    }
  },
  created() {
    if (this.byBranch) {
      this.queryParams.search = "";
    }
    this.getList(this.queryParams);
    this.handleMissingOpt();
  },
  methods: {
    onChangeBranch(newValue: string): void {
      this.queryParams.page = 0;
      this.queryParams.search = newValue ? `branchWarehouse.secureId~${newValue}` : "";
      this.optWarehouse = [];
      this.getList(this.queryParams);
    },
    getListWarehouse(params: RequestQueryParamsModel): Promise<ResponseListWarehouse> {
      return warehouseService.listWarehouse(params);
    },
    onSelect(val: string, meta: ResponseBranchWarehouse): void {
      this.$emit("input", val);
      this.$emit("on-select", { value: val, meta });
    },
    searchWarehouse(search = ""): void {
      if (search) {
        this.queryParams.search = `locationCode~*${search.toUpperCase()}*`;
      }
      if (this.byBranch) {
        this.queryParams.search = `branchWarehouse.secureId~${this.byBranch}_AND_name~*${search.toUpperCase()}*_OR_code~*${search.toUpperCase()}*`;
      }
      this.queryParams.page = 0;
      this.optWarehouse = [];
      this.getList(this.queryParams);
    },
    popupScroll(e): void {
      if ((this.dtListWarehouse.totalPages - 1) === this.dtListWarehouse.currentPage) return;
      const target = e.target;
      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
        this.queryParams.page += 1;
        this.getList(this.queryParams);
      }
    },
    async getList(params: RequestQueryParamsModel): Promise<void> {
      try {
        this.loading = true;
        const res = await this.getListWarehouse(params);
        const { optWarehouse } = this;
        const opts = res.data.map(x => (
          {
            key: x.code || "",
            value: x.id,
            meta: x
          }
        ));
        this.optWarehouse = [...optWarehouse, ...opts];
        this.dtListWarehouse = res;
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading = false;
      }
    },
    async handleMissingOpt(): Promise<void> {
      try {
        if (this.forList || !this.value) return;
        const opt = this.optWarehouse.find(x => x.value === this.value);
        if (!opt) {
          const { data } = await this.getListWarehouse({ search: `secureId~${this.value}` });
          const newOpt = { key: data[0].code || "", value: data[0].id, meta: data[0] };
          const { optWarehouse } = this;
          this.optWarehouse = [...optWarehouse, newOpt];
        }
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
    onChange(e): void {
      this.$emit("change", e);
      this.$emit("input", e);
    }
  }
});

